exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-abbeyroad-index-tsx": () => import("./../../../src/pages/abbeyroad/index.tsx" /* webpackChunkName: "component---src-pages-abbeyroad-index-tsx" */),
  "component---src-pages-about-bands-mdx-frontmatter-slug-tsx-content-file-path-bands-crossing-32nd-street-index-mdx": () => import("./../../../src/pages/about/bands/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/elliotreed_client/elliotreed_client/_bands/crossing-32nd-street/index.mdx" /* webpackChunkName: "component---src-pages-about-bands-mdx-frontmatter-slug-tsx-content-file-path-bands-crossing-32nd-street-index-mdx" */),
  "component---src-pages-about-bands-mdx-frontmatter-slug-tsx-content-file-path-bands-gypsy-swing-revue-index-mdx": () => import("./../../../src/pages/about/bands/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/elliotreed_client/elliotreed_client/_bands/gypsy-swing-revue/index.mdx" /* webpackChunkName: "component---src-pages-about-bands-mdx-frontmatter-slug-tsx-content-file-path-bands-gypsy-swing-revue-index-mdx" */),
  "component---src-pages-about-bands-mdx-frontmatter-slug-tsx-content-file-path-bands-puppies-with-guns-index-mdx": () => import("./../../../src/pages/about/bands/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/elliotreed_client/elliotreed_client/_bands/puppies-with-guns/index.mdx" /* webpackChunkName: "component---src-pages-about-bands-mdx-frontmatter-slug-tsx-content-file-path-bands-puppies-with-guns-index-mdx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-design-colors-tsx": () => import("./../../../src/pages/design/colors.tsx" /* webpackChunkName: "component---src-pages-design-colors-tsx" */),
  "component---src-pages-design-index-tsx": () => import("./../../../src/pages/design/index.tsx" /* webpackChunkName: "component---src-pages-design-index-tsx" */),
  "component---src-pages-design-typography-tsx": () => import("./../../../src/pages/design/typography.tsx" /* webpackChunkName: "component---src-pages-design-typography-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lessons-index-tsx": () => import("./../../../src/pages/lessons/index.tsx" /* webpackChunkName: "component---src-pages-lessons-index-tsx" */),
  "component---src-pages-performances-index-tsx": () => import("./../../../src/pages/performances/index.tsx" /* webpackChunkName: "component---src-pages-performances-index-tsx" */)
}

